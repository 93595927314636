/* i18n语言包（越南语） */

export default {
  "sys.operator.success": "Hoạt động thành công",
  "业务异常": "Kinh doanh bất thường",
  "网络请求失败，请稍候再试": "Yêu cầu mạng không thành công, vui lòng thử lại trong thời gian chờ",
  "您好！": "Xin chào!",
  "欢迎来到license！": "Chào mừng đến với License!",
  "请输入公司关键词": "Vui lòng nhập từ khóa công ty",
  "公司名不能为空": "Tên công ty không được để trống",
  "请输入用户名": "Vui lòng nhập tên người dùng",
  "用户名不能为空": "Tên người dùng không được để trống",
  "请输入密码": "Vui lòng nhập mật khẩu",
  "密码不能为空": "Mật khẩu không được để trống",
  "登录": "Đăng nhập",
  "退出登录": "Thoát Đăng nhập",
  "登录成功": "Đăng nhập thành công",
  "暂无权限，请联系后台管理员": "Không có quyền, vui lòng liên hệ với quản trị viên",
  "向右滑动完成拼图": "Trượt sang phải để hoàn thành câu đố",
  "刷新": "Làm mới",
  "抱歉！": "Xin lỗi!",
  "当前页面不存在...": "Trang hiện tại không tồn tại...",
  "请检查您输入的网址是否正确，或点击下面的按钮返回首页。": "Vui lòng kiểm tra xem địa chỉ web bạn đã nhập có chính xác không hoặc nhấp vào nút bên dưới để quay lại trang chủ.",
  "登录状态已过期，请重新登录!": "Trạng thái đăng nhập đã hết hạn, vui lòng đăng nhập lại!",
  "返回首页": "Quay lại trang đầu",
  "关闭其他": "Đóng khác", 
  "关闭左侧": "Đóng trái", 
  "关闭右侧": "Đóng bên phải", 
  "关闭全部": "Đóng tất cả",
  "搜索": "Tìm kiếm",
  "清空": "Trống rỗng",
  "添加": "Thêm",
  "删除": "Xoá",
  "批量删除": "Xóa hàng loạt",
  "启用": "Bật",
  "禁用": "Tắt",
  "编辑": "Chỉnh sửa",
  "修改": "Sửa đổi",
  "审核": "Đánh giá",
  "反审核": "Chống kiểm toán",
  "条码": "Mã vạch",
  "详情": "Chi tiết",
  "导出Excel": "Xuất Excel",
  "边框": "Viền",
  "大": "Lớn",
  "中": "Giữa",
  "小": "Nhỏ",
  "许可证书管理": "Quản lý giấy phép", 
  "许可证书": "Giấy phép",
  "请输入许可证名称": "Vui lòng nhập tên giấy phép",
  "系统管理": "Quản lý hệ thống", 
  "菜单管理": "Quản lý menu",
  "开始日期": "Ngày bắt đầu",
  "至": "Đến",
  "结束日期": "Ngày kết thúc",
  "ID或菜单名称": "ID hoặc tên menu",
  "请输入权限值": "Vui lòng nhập giá trị quyền",
  "菜单名称": "Tên thực đơn",
  "图标": "Biểu tượng",
  "选择图标": "Chọn biểu tượng",
  "组件路径": "Đường dẫn thành phần",
  "类型": "Loại",
  "排序": "Sắp xếp",
  "目录": "Thư mục",
  "菜单": "Thực đơn",
  "按钮": "Nút bấm",
  "未选中任何行": "Chưa chọn dòng nào",
  "操作成功": "Hoạt động thành công",
  "确认要启用选中的": "Xác nhận rằng bạn muốn bật lựa chọn",
  "确认要禁用选中的": "Xác nhận rằng bạn muốn vô hiệu hóa lựa chọn",
  "确认要删除选中的": "Xác nhận rằng bạn muốn xóa",
  "条数据？": "mục dữ liệu đã chọn?",
  "取消": "Hủy bỏ",
  "确定": "Xác định",
  "添加菜单": "Thêm menu",
  "编辑菜单": "Chỉnh sửa menu",
  "上级菜单": "Thực đơn cao hơn",
  "请选择上级菜单": "Vui lòng chọn menu cao hơn",
  "菜单类型": "Loại menu",
  "菜单图标": "Biểu tượng menu",
  "图标选择器": "Bộ chọn biểu tượng",
  "显示排序": "Hiện sắp xếp",
  "路由地址": "Địa chỉ định tuyến",
  "组件地址": "Địa chỉ thành phần",
  "路由重定向": "Chuyển hướng",
  "权限标识": "Nhận dạng quyền",
  "菜单状态": "Trạng thái menu",
  "是否隐藏": "Ẩn hay không",
  "请输入菜单名称": "Vui lòng nhập tên thực đơn",
  "请输入排序": "Vui lòng nhập sắp xếp",
  "请输入路由地址": "Vui lòng nhập địa chỉ route",
  "请输入组件地址": "Vui lòng nhập địa chỉ thành phần",
  "请输入路由重定向": "Vui lòng nhập route redirect",
  "请输入权限标识": "Vui lòng nhập nhận dạng quyền",
  "显示": "Hiển thị",
  "隐藏": "Ẩn",
  "添加子项": "Thêm mục con",
  "添加翻译": "Thêm bản dịch",
  "确认删除名称为": "Bạn có chắc chắn muốn xóa mục dữ liệu có tên",
  "的数据项?": "?",
  "语言类型": "Loại ngôn ngữ",
  "翻译内容": "Dịch nội dung",
  "请先选中某一数据行进行操作": "Vui lòng chọn một dòng dữ liệu để hoạt động",
  "请选择语言类型": "Vui lòng chọn loại ngôn ngữ",
  "请输入翻译内容": "Vui lòng nhập nội dung dịch",
  "确认要删除当前项吗？": "Xác nhận bạn muốn xóa mục hiện tại?",
  "确认要删除选中项吗？": "Xác nhận rằng bạn muốn loại bỏ lựa chọn?",
  "操作": "Hoạt động",
  "角色管理": "Quản lý vai trò",
  "角色编号": "Mã hóa ký tự",
  "角色名称": "Tên nhân vật",
  "角色编码": "Mã hóa ký tự",
  "角色描述": "Mô tả vai trò",
  "请输入角色编码": "Vui lòng nhập mã ký tự",
  "请输入角色名称": "Vui lòng nhập tên nhân vật",
  "请输入描述": "Vui lòng nhập mô tả",
  "描述": "Mô tả",
  "添加角色": "Thêm nhân vật",
  "编辑角色": "Vai trò biên tập",
  "权限设置": "Thiết lập quyền",
  "保存权限设置": "Lưu thiết lập quyền",
  "用户管理": "Quản lý người dùng",
  "请选择角色": "Vui lòng chọn nhân vật",
  "请选择部门": "Vui lòng chọn bộ phận",
  "用户编号": "Mã hóa người dùng",
  "用户头像": "Avatar người dùng",
  "账号": "Số tài khoản",
  "昵称": "Biệt danh",
  "角色": "Vai trò",
  "部门": "Trang chủ",
  "手机号码": "Số điện thoại",
  "邮箱": "Hộp thư",
  "状态": "Trạng thái",
  "账户": "Tài khoản",
  "姓名": "Tên",
  "密码": "Mật khẩu",
  "确认密码": "Xác nhận mật khẩu",
  "性别": "Giới tính",
  "男": "Nam",
  "女": "Phụ nữ",
  "职务": "Chức vụ",
  "员工编号": "Mã nhân viên",
  "出生日期": "Ngày sinh",
  "用户状态": "Trạng thái người dùng",
  "请输入账户": "Vui lòng nhập tài khoản",
  "请输入姓名": "Vui lòng nhập tên",
  "请输入确认密码": "Vui lòng nhập mật khẩu xác nhận",
  "请输入员工编号": "Vui lòng nhập mã nhân viên",
  "添加用户": "Thêm người dùng",
  "编辑用户": "Sửa người dùng",
  "请输入手机号码": "Vui lòng nhập số điện thoại di động",
  "请输入邮箱地址": "Vui lòng nhập địa chỉ hộp thư",
  "请选择职务": "Vui lòng chọn vị trí",
  "请选择出生日期": "Vui lòng chọn ngày sinh",
  "两次输入的密码不一致": "Mật khẩu nhập hai lần không phù hợp",
  "长度在 6 到 20 个字符": "Độ dài từ 6 đến 20 ký tự",
  "重置密码": "Đặt lại mật khẩu",
  "部门管理": "Quản lý bộ phận",
  "添加部门": "Thêm bộ phận",
  "编辑部门": "Phòng biên tập",
  "部门名称": "Tên bộ phận",
  "部门编号": "Bộ mã hóa",
  "部门负责人": "Trưởng phòng",
  "上级部门": "Bộ phận cao hơn",
  "负责人": "Trang chủ",
  "请选择上级部门": "Vui lòng chọn bộ phận cao hơn",
  "请输入部门名称": "Vui lòng nhập tên bộ phận",
  "职务管理": "Quản lý công việc",
  "部门职务管理": "Quản lý chức vụ bộ phận",
  "请选择部门负责人": "Vui lòng chọn Trưởng phòng",
  "选择负责人": "Chọn người chịu trách nhiệm",
  "返回": "Quay lại",
  "职务详情列表": "Danh sách chi tiết công việc",
  "职务名称": "Tên công việc",
  "创建人": "Tạo bởi",
  "创建人ID": "Tạo ID",
  "创建时间": "Thời gian tạo",
  "更新人": "Cập nhật bởi",
  "更新人ID": "Cập nhật ID",
  "更新时间": "Thời gian cập nhật",
  "请输入职务名称": "Vui lòng nhập tên công việc",
  "添加职务": "Thêm bài viết",
  "编辑职务": "Chức vụ biên tập",
  "字典管理": "Quản lý từ điển",
  "字典编号或名称": "Số từ điển hoặc tên",
  "添加字典": "Thêm từ điển",
  "编辑字典": "Sửa từ điển",
  "字典编号": "Mã hóa từ điển",
  "字典名称": "Từ điển Name",
  "选项名称": "Tùy chọnName",
  "选项值": "Giá trị tùy chọn",
  "请输入字典编号": "Vui lòng nhập mã hóa từ điển",
  "请输入字典名称": "Vui lòng nhập tên từ điển",
  "请输入选项值": "Vui lòng nhập giá trị tùy chọn",
  "请输入选项名称": "Vui lòng nhập tên tùy chọn",
  "备注": "Ghi chú",
  "请输入备注": "Vui lòng nhập ghi chú",
  "添加字典选项": "Thêm tùy chọn từ điển",
  "编辑字典选项": "Tùy chọn sửa từ điển",
  "字典列表": "Danh sách từ điển",
  "字典选项": "Tùy chọn từ điển",
  "翻译列表": "Danh sách dịch",
  "租户管理": "Quản lý khách thuê",
  "添加租户": "Thêm đối tượng thuê",
  "配置": "Cấu hình",
  "序号": "Số sê-ri",
  "租户logo": "Logo của người thuê",
  "租户名称": "Tên đối tượng thuê",
  "租户ID": "ID đối tượng thuê",
  "联系人": "Liên hệ",
  "联系电话": "Số điện thoại",
  "开始时间": "Thời gian bắt đầu",
  "结束时间": "Thời gian kết thúc",
  "请输入租户名称": "Vui lòng nhập tên đối tượng thuê",
  "请输入租户编码": "Vui lòng nhập mã đối tượng thuê",
  "请输入联系人": "Vui lòng nhập liên hệ",
  "请输入联系电话": "Vui lòng nhập số liên lạc",
  "请选择开始时间": "Vui lòng chọn thời gian bắt đầu",
  "请选择结束时间": "Vui lòng chọn thời gian kết thúc",
  "租户编码": "Mã hóa đối tượng thuê",
  "备注：租户默认管理用户：admin，密码为联系电话。": "Ghi chú Người thuê quản lý admin người dùng theo mặc định với mật khẩu là số điện thoại liên lạc.",
  "公司logo": "Logo công ty",
  "系统名称": "Tên hệ thống",
  "域名": "Tên miền",
  "复杂配置": "Cấu hình phức tạp",
  "邮件配置": "Cấu hình thư",
  "看板状态": "Trạng thái Kanban",
  "请输入系统名称": "Vui lòng nhập tên hệ thống",
  "请输入域名": "Vui lòng nhập tên miền",
  "logo裁剪": "Logo cắt",
  "预览": "Xem thử",
  "选择图片": "Chọn ảnh",
  "放大": "Phóng to",
  "缩小": "Thu nhỏ",
  "左旋转": "Xoay trái",
  "右旋转": "Xoay phải",
  "上传图片": "Upload ảnh",
  "推荐使用：透明白色logo；尺寸比例：宽200~240px，高57px。": "Đề nghị sử dụng logo màu trắng trong suốt; Tỷ lệ kích thước rộng 200~240px, cao 57px.",
  "操作日志": "Nhật ký hoạt động",
  "ID": "ID",
  "TraceId": "TraceId",
  "操作人": "Người vận hành",
  "执行方法": "Phương pháp thực hiện",
  "IP地址": "Địa chỉ IP",
  "地区": "Quận",
  "耗时": "Thời gian",
  "配置中心": "Trung tâm cấu hình",
  "客户资料": "Thông tin khách hàng",
  "请输入客户名称": "Vui lòng nhập tên khách hàng",
  "锡膏虚拟客户": "Khách hàng ảo của Solder Paste",
  "胶水虚拟客户": "Khách hàng ảo Keo",
  "自购料": "Tự mua nguyên liệu",
  "添加客户": "Thêm khách hàng",
  "编辑客户": "Chỉnh sửa khách hàng",
  "导入Excel": "Nhập Excel",
  "客户编号": "Mã khách hàng",
  "客户名称": "Tên khách hàng",
  "客户简称": "Khách hàng gọi tắt",
  "地址": "Địa chỉ",
  "是": "Vâng",
  "否": "Không",
  "客户地址": "Địa chỉ khách hàng",
  "详细地址": "Chi tiết địa chỉ",
  "联系方式": "Thông tin liên hệ",
  "请选择客户地址": "Vui lòng chọn địa chỉ khách hàng",
  "请输入客户编号": "Vui lòng nhập mã khách hàng",
  "请输入客户简称": "Vui lòng nhập tên khách hàng",
  "请输入详细地址": "Vui lòng nhập địa chỉ chi tiết",
  "请输入联系方式": "Vui lòng nhập thông tin liên hệ",
  "上传的文件大小不能超过100MB！": "Kích thước tập tin tải lên không được vượt quá 100MB!",
  "编辑租户": "Sửa đối tượng thuê",
  "租户状态": "Tình trạng thuê nhà",
  "暂无数据": "Không có dữ liệu",
  "请输入图标名称": "Vui lòng nhập tên biểu tượng",
  "添加选项": "Thêm tùy chọn",
  "关键词": "Từ khóa",
  "租户企业全称": "Tên đầy đủ của doanh nghiệp cho thuê",
  "系统版本信息": "Thông tin phiên bản hệ thống",
  "许可证书类型": "Loại giấy phép",
  "添加许可证书": "Thêm giấy phép",
  "导入服务器信息": "Nhập thông tin máy chủ",
  "许可证书序列号": "Số sê-ri giấy phép",
  "用户数": "Số lượng user",
  "设备数": "Số lượng thiết bị",
  "有效期": "Ngày hết hạn",
  "mac地址": "Địa chỉ MAC",
  "服务器唯一码": "Mã máy chủ duy nhất",
  "供应商全称": "Tên đầy đủ của nhà cung cấp",
  "下载": "Tải về",
  "下载成功": "Download thành công",
  "导出成功": "Xuất thành công",
  "租户": "Trang chủ",
  "选择租户": "Chọn đối tượng thuê",
  "请选择租户": "Vui lòng chọn đối tượng thuê",
  "请输入供应商全称": "Vui lòng nhập tên đầy đủ của nhà cung cấp",
  "请输入租户企业全称": "Vui lòng nhập tên đầy đủ của doanh nghiệp thuê nhà",
  "请输入系统版本信息": "Vui lòng nhập thông tin phiên bản hệ thống",
  "请选择许可证书类型": "Vui lòng chọn loại giấy phép",
  "请输入mac地址": "Vui lòng nhập địa chỉ MAC",
  "请输入许可证书序列号": "Vui lòng nhập số serial giấy phép",
  "请输入用户数": "Vui lòng nhập số user",
  "请输入设备数": "Vui lòng nhập số thiết bị",
  "请选择有效期": "Vui lòng chọn ngày hết hạn",
  "租户编号": "Số thuê nhà",
  "服务器配置": "Cấu hình máy phục vụ",
  "添加服务器": "Thêm máy chủ",
  "编辑服务器": "Sửa máy phục vụ",
  "cpu内核信息": "Thông tin hạt nhân CPU",
  "逻辑cpu核数": "Số lõi CPU logic",
  "物理cpu核数": "Số lõi CPU vật lý",
  "系统内存信息": "Thông tin bộ nhớ hệ thống",
  "处理器id": "Bộ xử lý ID",
  "操作系统厂商": "Nhà sản xuất hệ điều hành",
  "型号": "Mô hình",
  "未知": "Không rõ",
  "请输入cpu内核信息": "Vui lòng nhập thông tin hạt nhân CPU",
  "请输入逻辑cpu核数": "Nhập số lõi CPU logic",
  "请输入物理cpu核数": "Vui lòng nhập số lõi CPU vật lý",
  "请输入系统内存信息": "Vui lòng nhập thông tin bộ nhớ hệ thống",
  "请输入处理器id": "Vui lòng nhập ID bộ xử lý",
  "请输入操作系统厂商": "Vui lòng nhập nhà sản xuất hệ điều hành",
  "请输入型号": "Vui lòng nhập mẫu",
  "提示：": "Gợi ý:",
  "一级目录组件地址：": "Địa chỉ thành phần thư mục cấp 1:",
  "子级目录组件地址：": "Địa chỉ thành phần thư mục con:",
  "记住账号密码": "Ghi nhớ mật khẩu tài khoản",
  "租户全称": "Tên đầy đủ của người thuê",
  "请输入租户全称": "Vui lòng nhập tên đầy đủ của đối tượng thuê",
  "数据看板": "Kanban dữ liệu",
  "DIP生产": "Sản xuất DIP",
  "SMT生产": "Sản xuất SMT",
  "温湿度监控": "Giám sát độ ẩm nhiệt độ ",
  "静电监控": "Giám sát tĩnh điện",
  "ESD静电监控": "Giám sát tĩnh điện ESD",
  "智能仓储": "Kho thông minh",
  "星期日": "Chủ nhật",
  "星期一": "Thứ hai",
  "星期二": "Thứ ba",
  "星期三": "Thứ tư",
  "星期四": "Thứ năm",
  "星期五": "Thứ sáu",
  "星期六": "Thứ bảy",
  "生产板面": "Sản xuất mặt tấm",
  "拼板数量": "Số lượng ván ghép",
  "生产进度": "Tiến độ sản xuất",
  "达成率": "Tỷ lệ đạt được",
  "直通率": "Thông qua tỷ lệ",
  "完成数量": "Số lượng hoàn thành",
  "工单批量": "Đơn đặt hàng số lượng lớn",
  "个": "chiếc",
  "已完成": "Đã hoàn thành",
  "未完成": "Chưa hoàn thành",
  "已用工时": "Giờ làm việc đã được sử dụng",
  "剩余工时": "Số giờ còn lại",
  "计划总工时": "Khi lập kế hoạch tổng công",
  "时": "Khi",
  "生产信息": "Thông tin sản xuất",
  "生产机型": "Mô hình sản xuất",
  "工令单号": "Đơn đặt hàng",
  "机型标产": "Mô hình sản xuất tiêu chuẩn",
  "客户订单号": "Số đặt hàng của khách hàng",
  "机种名称": "Loại máy Name",
  "低位预警": "Cảnh báo thấp",
  "设备状态": "Trạng thái thiết bị",
  "不良TOP5": "Top5 xấu",
  "设备抛料率": "Tỷ lệ ném thiết bị",
  "接料预警": "Cảnh báo tiếp nhiên liệu",
  "站位": "Vị trí đứng",
  "用量": "Liều dùng",
  "剩余物料": "Vật liệu còn lại",
  "剩余板数": "Số lượng tấm còn lại",
  "预计用时（小时）": "Thời gian dự kiến (giờ)",
  "生产计划": "Kế hoạch sản xuất",
  "客户": "Khách hàng",
  "产品型号": "Mô hình sản phẩm",
  "产品名称": "Tên sản phẩm",
  "订单号": "Số thứ tự",
  "批量": "Số lượng lớn",
  "白班": "Ban ngày",
  "生产数量(个)": "Số lượng sản xuất (PCS)",
  "达成率(%)": "Tỷ lệ hoàn thành (%)",
  "AOI警戒线(99.48)%": "Dây cảnh báo AOI (99,48%)",
  "SPI警戒线(99.48)%": "Dây SPI (99.48%)",
  "0-1点": "0-1h",
  "1-2点": "1-2h",
  "2-3点": "2-3h",
  "3-4点": "3-4h",
  "4-5点": "4-5h",
  "5-6点": "5-6h",
  "6-7点": "6-7h",
  "7-8点": "7-8h",
  "8-9点": "8-9h",
  "9-10点": "9-10h",
  "10-11点": "10-11h",
  "11-12点": "11-12h",
  "12-13点": "12-13h",
  "13-14点": "13-14h",
  "14-15点": "14-15h",
  "15-16点": "15-16h",
  "16-17点": "16-17h",
  "17-18点": "17-18h",
  "18-19点": "18-19h",
  "19-20点": "19-20h",
  "20-21点": "20-21h",
  "21-22点": "21-22h",
  "22-23点": "22-23h",
  "23-0点": "23-0h",
  "在制工单": "Trong lệnh làm việc",
  "成品名称": "Tên sản phẩm",
  "批量数量": "Số lượng lô",
  "合格率": "Tỷ lệ vượt qua",
  "平衡率": "Tỷ lệ cân bằng",
  "DIP外观缺陷柏拉图": "Lỗi xuất hiện DIP Plato",
  "不良品数量(个)": "Số lượng sản phẩm xấu",
  "累计不良占比(%)": "Tích lũy tỷ lệ xấu (%)",
  "DIP功能缺陷柏拉图": "Lỗi chức năng DIP Plato",
  "合格率曲线实时推移率": "Đường cong tỷ lệ vượt qua Tỷ lệ chuyển tiếp theo thời gian thực",
  "线别负责人": "Người phụ trách đường dây",
  "A班组长": "Trưởng nhóm A",
  "A班IPQC": "Lớp A IPQC",
  "A班工程师": "Kỹ sư lớp A",
  "B班组长": "Trưởng nhóm B",
  "B班IPQC": "Lớp B IPQC",
  "B班工程师": "Kỹ sư lớp B",
  "UPPH实时推移图": "Biểu đồ thời gian thực UPPH",
  "后焊": "Hàn sau",
  "烧录": "Ghi âm",
  "测试": "Thử nghiệm",
  "插件": "Phần bổ sung",
  "抛料": "Vật liệu ném",
  "率值设置": "Cài đặt giá trị tỷ lệ",
  "全部率值": "Giá trị tỷ lệ đầy đủ",
  "清除": "Xoá",
  "夜班": "Ca đêm",
  "实时良率": "Thời gian thực",
  "AOI警戒线": "Dây AOI",
  "SPI警戒线": "Dây SPI",
  "AOI直通率(%)": "Tỷ lệ thông qua AOI (%)",
  "SPI直通率(%)": "Tỷ lệ thông qua SPI (%)",
  "生产性": "Sản phẩm",
  "工单进度": "Tiến độ công việc",
  "小时": "Giờ",
  "剩余时间": "Thời gian còn lại",
  "物料编号": "Mã hóa vật liệu",
  "物料名称": "Tên vật liệu",
  "物料规格": "Thông số vật liệu",
  "计划序列号": "Số sê-ri kế hoạch",
  "板面": "Mặt tấm",
  "供应单号": "Số đơn cung cấp",
  "试验": "Thử nghiệm",
  "合格率趋势图": "Biểu đồ xu hướng tỷ lệ vượt qua",
  "分钟": "phút",
  "温度": "Nhiệt độ",
  "最高温度": "Nhiệt độ tối đa",
  "最低温度": "Nhiệt độ thấp nhất",
  "湿度": "Độ ẩm",
  "最高湿度": "Độ ẩm tối đa",
  "最低湿度": "Độ ẩm thấp nhất",
  "暂无数据~": "Tạm thời không có dữ liệu~",
  "仓房设置": "Thiết lập kho",
  "厂房名称": "Tên nhà máy",
  "车间滚动显示": "Hiển thị cuộn hội thảo",
  "地阻值": "Trở kháng đất",
  "合格": "Chứng nhận",
  "不合格": "Không đủ tiêu chuẩn",
  "离线": "Ngoại tuyến",
  "离岗": "Rời vị trí",
  "未入库单数(个)": "Số ít chưa nhập kho",
  "未备料单数(个)": "Số lượng ít chưa dự trữ (chiếc)",
  "退料单数(个)": "Số lượng trả lại (chiếc)",
  "超领单数(个)": "Số lượng siêu nhỏ",
  "出库单数(个)": "Số lượng ra kho",
  "入库进度": "Tiến trình nhập kho",
  "退料进度": "Tiến độ trả lại",
  "出库进度": "Tiến trình xuất kho",
  "备料进度": "Tiến độ chuẩn bị",
  "超领进度": "Tiến độ vượt mức",
  "出库中": "Xuất kho",
  "入库中": "Nhập kho",
  "备料中": "Trong chuẩn bị",
  "退料中": "Đang trả lại",
  "超领中": "Siêu cổ",
  "出库状态": "Trạng thái thư viện",
  "成品编号": "Số sản phẩm",
  "当前(台)/累计(台)": "B5-05=giá trị thông số Kd, (cài 2)",
  "未出货数量(台)": "Số lượng chưa xuất hàng",
  "完成进度": "Hoàn thành tiến độ",
  "入库状态": "Trạng thái thư viện",
  "日志账号": "Tài khoản nhật ký",
  "来料类型": "Loại vật liệu đến",
  "入库数量": "Số lượng vào kho",
  "仓库名称": "Tên kho",
  "等待时长(时)": "Thời gian chờ (khi)",
  "备料状态": "Trạng thái chuẩn bị",
  "工令单号/工单组名称": "Số lệnh công việc/Tên nhóm lệnh công việc",
  "退料状态": "Trạng thái trả lại",
  "超领状态": "Trạng thái siêu cổ",
  "页面配置": "Cấu hình trang",
  "表格滚动显示": "Hiển thị cuộn bảng",
  "开启": "Mở",
  "关闭": "Đóng cửa",
  "动画效果": "Hiệu ứng hoạt hình",
  "自动登录成功": "Tự động đăng nhập thành công",
  "自动登录中...": "Tự động đăng nhập...",
  '在线': 'Trực tuyến',
  '正常': 'Bình thường',
  '异常': 'Bất thường',
  '语音测试':'Kiểm tra giọng nói.',
  '语音测试内容':'Nội dung kiểm tra giọng nói.',
  '请输入语音测试内容':'Vui lòng nhập nội dung kiểm tra giọng nói',
  '语音测试成功！': 'Kiểm tra giọng nói thành công!',
  '今日已收货单数': 'Hôm nay đã nhận hàng',
  '今日已入库单数': '今日已入库单数',
  '今日已发料单数': 'Hôm nay nhập kho số lượng',
  '今日已退料单数': 'Hôm nay đã trả lại số lượng',
  '今日已出库单数': 'Hôm nay số lượng kho',
  '烘烤中': 'Đang nướng',
  '烘烤状态':'Trạng thái nướng',
  '条码号':'Số mã vạch',
  '烘烤温度':'Nhiệt độ nướng',
  '当前达成率':'Tỷ lệ hoàn thành hiện tại',
  '当前完成数':'Số hoàn thành hiện tại',
  '工单数':'Số lượng công việc',
  '今日直通率':'Hôm nay tỷ lệ trực tiếp',
  '今日达成率':'Tỷ lệ đạt được hôm nay',
  '订单完成进度':'Tiến độ hoàn thành đơn hàng',
  '实时产量与达成率':'Sản lượng thời gian thực và tỷ lệ đạt được',
  '导入license': 'Nhập license',
  '导出serverInfo': 'Xuất ServerInfo',
  'license文件不存在': 'Tập tin license không tồn tại',
  '您的系统已过期，当前无法使用系统，请先更新许可证书!': 'Hệ thống của bạn đã hết hạn và hiện không thể sử dụng hệ thống, vui lòng cập nhật giấy phép trước!',
  '服务器 MAC 地址不匹配': 'Địa chỉ MAC của máy chủ không khớp',
  '服务器机器码不匹配': 'Mã máy chủ không khớp',
  'license验签失败!': 'Kiểm tra license thất bại!',
  '您的系统使用期限还有': 'Thời hạn sử dụng hệ thống của bạn còn',
  '天即将过期，过期后将不能继续使用系统，请及时处理!': 'ngày nữa sắp hết hạn, sau khi hết hạn sẽ không thể tiếp tục sử dụng hệ thống, xin xử lý kịp thời!',
  '正在出货': 'Giao hàng',
  '部分出货': 'Vận chuyển một phần',
  '已入库': 'Đã nhập kho',
  '锁料完成': 'Khóa hoàn thành',
  '正在捡料': 'Đang nhặt vật liệu.',
  '单': 'Độc thân',
  '组': 'Nhóm',
  '部分退料': 'Phần trả lại',
  '已发料': 'Đã gửi',
  '包装 ': 'Đóng gói',
  'A班助拉': 'Lớp A hỗ trợ',
  'B班助拉': 'Lớp B hỗ trợ',
  'A班操作员': 'Đội A Operator',
  'B班操作员': 'Đội B Operator',
  '湿敏元器件烘烤剩余时间': 'Thời gian nướng linh kiện nhạy cảm ướt',
  '湿敏等级': 'Độ nhạy ướt',
  '生成达成率': 'Tạo tỷ lệ đạt được',
}
/*
  注意事项：
    1、中文的 ‘编号’ 翻译为越南语时会变成 ‘数量’ 的意思，所以要用 ‘编码’ 去翻译；
*/