/* i18n语言包（英语） */

export default {
  "sys.operator.success": "sys.operator.success",
  "业务异常": "Business Exception",
  "网络请求失败，请稍候再试": "Network request failed, please try again later",
  "您好！": "Hello!",
  "欢迎来到license！": "Welcome to license!",
  "请输入公司关键词": "Please enter company keywords",
  "公司名不能为空": "Company name cannot be empty",
  "请输入用户名": "Please enter a name",
  "用户名不能为空": "Name cannot be empty",
  "请输入密码": "Please enter the password",
  "密码不能为空": "Password cannot be empty",
  "登录": "log on",
  "退出登录": "Log out of login",
  "登录成功": "Login succeeded",
  "暂无权限，请联系后台管理员": "No permission currently, please contact the backend administrator",
  "向右滑动完成拼图": "Slide right to complete the puzzle",
  "刷新": "Refresh",
  "抱歉！": "Sorry!",
  "当前页面不存在...": "The current page does not exist...",
  "请检查您输入的网址是否正确，或点击下面的按钮返回首页。": "Please check if the website address you entered is correct, or click the button below to return to the homepage.",
  "登录状态已过期，请重新登录!": "Login status has expired, please log in again!",
  "返回首页": "Back homePage",
  "关闭其他": "Close Other", 
  "关闭左侧": "Close Left side", 
  "关闭右侧": "Close Right Side", 
  "关闭全部": "Close All", 
  "搜索": "Search",
  "清空": "Empty",
  "添加": "Add",
  "删除": "Delete",
  "批量删除": "Batch Delete",
  "启用": "Enable",
  "禁用": "Disable",
  "编辑": "Edit",
  "修改": "Modify",
  "审核": "Approve",
  "反审核": "De-approval",
  "条码": "bar code",
  "详情": "Details",
  "导出Excel": "Export to Excel",
  "边框": "Border",
  "大": "Large",
  "中": "Medium",
  "小": "Small",
  "许可证书管理": "License Management", 
  "许可证书": "License", 
  "请输入许可证名称": "Please enter the license name",
  "系统管理": "System manage", 
  "菜单管理": "Menu manage", 
  "开始日期": "Start date",
  "至": "to",
  "结束日期": "End date",
  "ID或菜单名称": "ID or menu name",
  "请输入权限值": "Please enter permission values",
  "菜单名称": "Menu name",
  "图标": "Icon",
  "选择图标": "Select icon",
  "组件路径": "Component Path",
  "类型": "Type",
  "排序": "Sort",
  "目录": "Directory",
  "菜单": "Menu",
  "按钮": "Button",
  "未选中任何行": "No rows selected",
  "操作成功": "Operation successful",
  "确认要启用选中的": "Are you sure you want to enable the selected",
  "确认要禁用选中的": "Are you sure you want to disable the selected",
  "确认要删除选中的": "Confirm to delete the selected",
  "条数据？": "pieces of data?",
  "取消": "cancel",
  "确定": "confirm",
  "添加菜单": "Add menu",
  "编辑菜单": "Edit menu",
  "上级菜单": "Parent menu",
  "请选择上级菜单": "Please select the parent menu",
  "菜单类型": "Menu type",
  "菜单图标": "Menu icon",
  "图标选择器": "Icon selector",
  "显示排序": "Display sorting",
  "路由地址": "Routing address",
  "组件地址": "Component Address",
  "路由重定向": "Routing redirection",
  "权限标识": "Permission identification",
  "菜单状态": "Menu status",
  "是否隐藏": "Hidden",
  "请输入菜单名称": "Please enter a menu name",
  "请输入排序": "Please enter sorting",
  "请输入路由地址": "Please enter a routing address",
  "请输入组件地址": "Please enter the component address",
  "请输入路由重定向": "Please enter route redirection",
  "请输入权限标识": "Please enter the permission identifier",
  "显示": "Display",
  "隐藏": "Hidden",
  "添加子项": "Add Child",
  "添加翻译": "Add translate",
  "确认删除名称为": "Are you sure you want to delete the data item named",
  "的数据项?": "?",
  "语言类型": "Language Type",
  "翻译内容": "Translation content",
  "请先选中某一数据行进行操作": "Please select a certain menu line for operation first",
  "请选择语言类型": "Please select a language type",
  "请输入翻译内容": "Please enter the translation content",
  "确认要删除当前项吗？": "Are you sure you want to delete the current item?",
  "确认要删除选中项吗？": "Are you sure you want to delete the selected item?",
  "操作": "Operate",
  "角色管理": "Role manage",
  "角色编号": "Role Number",
  "角色名称": "Role Name",
  "角色编码": "Role Code",
  "角色描述": "Role Description",
  "请输入角色编码": "Please enter role code",
  "请输入角色名称": "Please enter a role name",
  "请输入描述": "Please enter a description",
  "描述": "Description",
  "添加角色": "Add role",
  "编辑角色": "Edit role",
  "权限设置": "Permission settings",
  "保存权限设置": "Save permission settings",
  "用户管理": "User management",
  "请选择角色": "Please select a role",
  "请选择部门": "Please select a department",
  "用户编号": "User ID",
  "用户头像": "User profile",
  "账号": "Account",
  "昵称": "Nickname",
  "角色": "Role",
  "部门": "Department",
  "手机号码": "Mobile phone number",
  "邮箱": "Mailbox",
  "状态": "State",
  "账户": "Account",
  "姓名": "Name",
  "密码": "Password",
  "确认密码": "Confirm Password",
  "性别": "Gender",
  "男": "Male",
  "女": "Female",
  "职务": "Post",
  "员工编号": "Employee ID",
  "出生日期": "Date of birth",
  "用户状态": "User Status",
  "请输入账户": "Please enter an account",
  "请输入姓名": "Please enter your name",
  "请输入确认密码": "Please enter a confirmation password",
  "请输入员工编号": "Please enter employee number",
  "添加用户": "Add user",
  "编辑用户": "Edit user",
  "请输入手机号码": "Please enter your phone number",
  "请输入邮箱地址": "Please enter an email address",
  "请选择职务": "Please select a position",
  "请选择出生日期": "Please select a date of birth",
  "两次输入的密码不一致": "Inconsistent passwords entered twice",
  "长度在 6 到 20 个字符": "Length between 6 and 20 characters",
  "重置密码": "Reset password",
  "部门管理": "Department Management",
  "添加部门": "Add Department",
  "编辑部门": "Edit Department",
  "部门名称": "Department Name",
  "部门编号": "Department Number",
  "部门负责人": "Department Head",
  "上级部门": "Superior department",
  "负责人": "Responsible person",
  "请选择上级部门": "Please select a superior department",
  "请输入部门名称": "Please enter the department name",
  "职务管理": "Duties Management",
  "部门职务管理": "Department duties Management",
  "请选择部门负责人": "Please select department head",
  "选择负责人": "Select responsible person",
  "返回": "返回",
  "职务详情列表": "Duties Details List",
  "职务名称": "Duties Name",
  "创建人": "Created by",
  "创建人ID": "Creator ID",
  "创建时间": "Creation time",
  "更新人": "Updated by",
  "更新人ID": "Updated by ID",
  "更新时间": "Update time",
  "请输入职务名称": "Please enter duties name",
  "添加职务": "Add duties",
  "编辑职务": "Edit duties",
  "字典管理": "Dictionary Management",
  "字典编号或名称": "dictionary number or name",
  "添加字典": "Add Dictionary",
  "编辑字典": "Edit Dictionary",
  "字典编号": "Dictionary number",
  "字典名称": "Dictionary name",
  "选项名称": "Option Name",
  "选项值": "Option Value",
  "请输入字典编号": "Please enter dictionary number",
  "请输入字典名称": "Please enter a dictionary name",
  "请输入选项值": "Please enter option value",
  "请输入选项名称": "Please enter option name",
  "备注": "Remarks",
  "请输入备注": "Please enter comments",
  "添加字典选项": "Add Dictionary Options",
  "编辑字典选项": "Edit Dictionary Options",
  "字典列表": "Dictionary List",
  "字典选项": "Dictionary Options",
  "翻译列表": "Translation List",
  "租户管理": "Tenant Management",
  "添加租户": "Add Tenant",
  "配置": "Config",
  "序号": "Serial Number",
  "租户logo": "Tenant logo",
  "租户名称": "Tenant Name",
  "租户ID": "Tenant ID",
  "联系人": "Contact Person",
  "联系电话": "Contact phone number",
  "开始时间": "Start Time",
  "结束时间": "End time",
  "请输入租户名称": "Please enter the tenant name",
  "请输入租户编码": "Please enter tenant code",
  "请输入联系人": "Please enter a contact person",
  "请输入联系电话": "Please enter the contact phone number",
  "请选择开始时间": "Please select a start time",
  "请选择结束时间": "Please select the end time",
  "租户编码": "Tenant Code",
  "备注：租户默认管理用户：admin，密码为联系电话。": "Note: Tenant default management user: admin, password is contact phone number.",
  "公司logo": "Company logo",
  "系统名称": "System Name",
  "域名": "Domain name",
  "复杂配置": "Complex configuration",
  "邮件配置": "Mail Configuration",
  "看板状态": "Bulletin board status",
  "请输入系统名称": "Please enter the system name",
  "请输入域名": "Please enter the domain name",
  "logo裁剪": "Logo cropping",
  "预览": "preview",
  "选择图片": "Select Picture",
  "放大": "enlarge",
  "缩小": "narrow",
  "左旋转": "Left rotation",
  "右旋转": "Right rotation",
  "上传图片": "Upload Picture",
  "推荐使用：透明白色logo；尺寸比例：宽200~240px，高57px。": "Recommended use: Transparent white logo; Size ratio: width 200-240px, height 57px.",
  "操作日志": "Operation log",
  "ID": "ID",
  "TraceId": "TraceId",
  "操作人": "Operator",
  "执行方法": "Execution method",
  "IP地址": "IP address",
  "地区": "Region",
  "耗时": "Time consuming",
  "配置中心": "Configuration Center",
  "客户资料": "Customer Information",
  "请输入客户名称": "Please enter the customer name",
  "锡膏虚拟客户": "Solder Paste Virtual Customer",
  "胶水虚拟客户": "Glue virtual customer",
  "自购料": "Self purchased materials",
  "添加客户": "Add Customer",
  "编辑客户": "Edit Customer",
  "导入Excel": "Import Excel",
  "客户编号": "Customer number",
  "客户名称": "Customer Name",
  "客户简称": "Customer Abbreviation",
  "地址": "Address",
  "是": "Yes",
  "否": "No",
  "客户地址": "Customer Address",
  "详细地址": "Detailed address",
  "联系方式": "Contact information",
  "请选择客户地址": "Please select a customer address",
  "请输入客户编号": "Please enter the customer number",
  "请输入客户简称": "Please enter the customer's short name",
  "请输入详细地址": "Please enter detailed address",
  "请输入联系方式": "Please enter contact information",
  "上传的文件大小不能超过100MB！": "The size of the uploaded file cannot exceed 100MB!",
  "编辑租户": "Edit Tenant",
  "租户状态": "Tenant Status",
  "暂无数据": "No Data",
  "请输入图标名称": "Please enter an icon name",
  "添加选项": "Add Options",
  "关键词": "Keyword",
  "租户企业全称": "Full name of tenant enterprise",
  "系统版本信息": "System version information",
  "许可证书类型": "License Type",
  "添加许可证书": "Add License Certificate",
  "导入服务器信息": "Import Server Information",
  "许可证书序列号": "License Certificate Serial Number",
  "用户数": "Number of users",
  "设备数": "Number of devices",
  "有效期": "Validity period",
  "mac地址": "MAC address",
  "服务器唯一码": "Server Unique Code",
  "供应商全称": "Full name of supplier",
  "下载": "Download",
  "下载成功": "Download successful",
  "导出成功": "Export successful",
  "租户": "Tenant",
  "选择租户": "Select Tenant",
  "请选择租户": "Please select Tenant",
  "请输入供应商全称": "Please enter the full name of the supplier",
  "请输入租户企业全称": "Please enter the full name of the tenant's enterprise",
  "请输入系统版本信息": "Please enter system version information",
  "请选择许可证书类型": "Please select the type of license certificate",
  "请输入mac地址": "Please enter the MAC address",
  "请输入许可证书序列号": "Please enter the license certificate serial number",
  "请输入用户数": "Please enter the number of users",
  "请输入设备数": "Please enter the number of devices",
  "请选择有效期": "Please select an expiration date",
  "租户编号": "Tenant ID",
  "服务器配置": "Server Configuration",
  "添加服务器": "Add Server",
  "编辑服务器": "Edit Server",
  "cpu内核信息": "CPU kernel information",
  "逻辑cpu核数": "Number of logical CPU cores",
  "物理cpu核数": "Number of physical CPU cores",
  "系统内存信息": "System Memory Information",
  "处理器id": "Processor ID",
  "操作系统厂商": "Operating system manufacturer",
  "型号": "Model",
  "未知": "Unknown",
  "请输入cpu内核信息": "Please enter CPU kernel information",
  "请输入逻辑cpu核数": "Please enter the number of logical CPU cores",
  "请输入物理cpu核数": "Please enter the number of physical CPU cores",
  "请输入系统内存信息": "Please enter system memory information",
  "请输入处理器id": "Please enter the processor ID",
  "请输入操作系统厂商": "Please enter the operating system manufacturer",
  "请输入型号": "Please enter the model number",
  "提示：": "Reminder:",
  "一级目录组件地址：": "First level directory component address:",
  "子级目录组件地址：": "Sub directory component address:",
  "记住账号密码": "Remember account password",
  "租户全称": "Full name of tenant",
  "请输入租户全称": "Please enter the full name of the tenant",
  "数据看板": "Data Dashboard",
  "DIP生产": "DIP production",
  "SMT生产": "SMT production",
  "温湿度监控": "Temperature and humidity monitoring",
  "静电监控": "Static Monitoring",
  "ESD静电监控": "ESD static monitoring",
  "智能仓储": "Intelligent warehousing",
  "星期日": "Sunday",
  "星期一": "Monday",
  "星期二": "Tuesday",
  "星期三": "Wednesday",
  "星期四": "Thursday",
  "星期五": "Friday",
  "星期六": "Saturday",
  "生产板面": "Production board surface",
  "拼板数量": "Assembly quantity",
  "生产进度": "Production schedule",
  "达成率": "Achievement rate",
  "直通率": "Passthrough rate",
  "完成数量": "Completed quantity",
  "工单批量": "Work Order Batch",
  "个": "pcs",
  "已完成": "Completed",
  "未完成": "Incomplete",
  "已用工时": "used",
  "剩余工时": "residue",
  "计划总工时": "Planned total working hours",
  "时": "h",
  "生产信息": "production information",
  "生产机型": "Production model",
  "工令单号": "Work order number",
  "机型标产": "Model standard production",
  "客户订单号": "Customer order number",
  "机种名称": "Model name",
  "低位预警": "Low level warning",
  "设备状态": "Device status",
  "不良TOP5": "Poor TOP5",
  "设备抛料率": "Equipment throwing rate",
  "接料预警": "Material receiving warning",
  "站位": "Station position",
  "用量": "dosage",
  "剩余物料": "surplus material",
  "剩余板数": "Number of remaining boards",
  "预计用时（小时）": "Estimated time (hours)",
  "生产计划": "Production plan",
  "客户": "customer",
  "产品型号": "Product model",
  "产品名称": "Product Name",
  "订单号": "Order number",
  "批量": "batch",
  "白班": "Day shift",
  "生产数量(个)": "Production quantity (pieces)",
  "达成率(%)": "Achievement rate (%)",
  "AOI警戒线(99.48)%": "AOI warning line (99.48)%",
  "SPI警戒线(99.48)%": "SPI warning line (99.48)%",
  "0-1点": "0-1h",
  "1-2点": "1-2h",
  "2-3点": "2-3h",
  "3-4点": "3-4h",
  "4-5点": "4-5h",
  "5-6点": "5-6h",
  "6-7点": "6-7h",
  "7-8点": "7-8h",
  "8-9点": "8-9h",
  "9-10点": "9-10h",
  "10-11点": "10-11h",
  "11-12点": "11-12h",
  "12-13点": "12-13h",
  "13-14点": "13-14h",
  "14-15点": "14-15h",
  "15-16点": "15-16h",
  "16-17点": "16-17h",
  "17-18点": "17-18h",
  "18-19点": "18-19h",
  "19-20点": "19-20h",
  "20-21点": "20-21h",
  "21-22点": "21-22h",
  "22-23点": "22-23h",
  "23-0点": "23-0h",
  "在制工单": "Work order in progress",
  "成品名称": "Name of finished product",
  "批量数量": "Batch quantity",
  "合格率": "Qualification rate",
  "平衡率": "Equilibrium rate",
  "DIP外观缺陷柏拉图": "DIP Appearance Defects Plato",
  "不良品数量(个)": "Number of defective products (pieces)",
  "累计不良占比(%)": "Accumulated defect ratio (%)",
  "DIP功能缺陷柏拉图": "DIP Functional Defect Plato",
  "合格率曲线实时推移率": "Real time transition rate of qualification rate curve",
  "线别负责人": "Line manager",
  "A班组长": "A leader",
  "A班IPQC": "A IPQC",
  "A班工程师": "A Engineer",
  "B班组长": "B leader",
  "B班IPQC": "B IPQC",
  "B班工程师": "B Engineer",
  "UPPH实时推移图": "UPPH real-time transition diagram",
  "后焊": "Post welding",
  "烧录": "Burn",
  "测试": "test",
  "插件": "Plugins",
  "抛料": "Throwing",
  "率值设置": "Rate value setting",
  "全部率值": "Total rate value",
  "清除": "clean up",
  "夜班": "night shift",
  "实时良率": "Real time yield",
  "AOI警戒线": "AOI warning line",
  "SPI警戒线": "SPI warning line",
  "AOI直通率(%)": "AOI Passthrough Rate (%)",
  "SPI直通率(%)": "SPI Passthrough Rate (%)",
  "生产性": "productive",
  "工单进度": "Work Order Progress",
  "小时": "hour",
  "剩余时间": "remainder",
  "物料编号": "Material No",
  "物料名称": "Material Name",
  "物料规格": "material specification",
  "计划序列号": "Plan serial number",
  "板面": "Board surface",
  "供应单号": "Supply order number",
  "试验": "test",
  "合格率趋势图": "Trend chart of qualification rate",
  "分钟": "minute",
  "温度":"temperature",
  "最高温度":"maximum temperature",
  "最低温度":"Minimum temperature",
  "湿度":"humidity",
  "最高湿度":"Maximum humidity",
  "最低湿度":"Minimum humidity",
  "暂无数据~":"There is currently no data available~",
  "仓房设置":"Warehouse setup",
  "厂房名称":"Factory name",
  "车间滚动显示":"Workshop scrolling display",
  "地阻值":"Ground resistance value",
  "合格":"qualified",
  "不合格":"Unqualified",
  "离线":"off-line",
  "离岗":"be demobilized",
  "未入库单数(个)":"Number of unreceived orders (pieces)",
  "未备料单数(个)":"Number of Unprepared Orders (pieces)",
  "退料单数(个)":"Number of return orders (pieces)",
  "超领单数(个)":"Number of super orders (pieces)",
  "出库单数(个)":"Number of outbound orders (pieces)",
  "入库进度":"Inventory progress",
  "退料进度":"Return progress",
  "出库进度":"Outbound progress",
  "备料进度":"Material preparation progress",
  "超领进度":"Over claiming progress",
  "出库中":"Outbound",
  "入库中":"In storage",
  "备料中":"In preparation",
  "退料中":"Returning materials",
  "超领中":"Super collar",
  "出库状态":"Outbound status",
  "成品编号":"Finished product number",
  "当前(台)/累计(台)":"Current/Accumulated",
  "未出货数量(台)":"Unshipped quantity (unit)",
  "完成进度":"Completion progress",
  "入库状态":"Entry status",
  "日志账号":"Log account",
  "来料类型":"Type of incoming materials",
  "入库数量":"Inventory quantity",
  "仓库名称":"Warehouse name",
  "等待时长(时)":"Waiting time (hours)",
  "备料状态":"Material preparation status",
  "工令单号/工单组名称":"Work Order Number/Work Order Group Name",
  "退料状态":"Return status",
  "超领状态":"Super collar status",
  "页面配置": "Page Configuration",
  "表格滚动显示": "Table scrolling display",
  "开启": "open",
  "关闭": "close",
  "动画效果": "Animation effects",
  "自动登录成功": "Automatic login successful",
  "自动登录中...": "Automatically logging in",
  '在线': 'on line',
  '正常': 'normal',
  '异常': 'abnormal',
  '语音测试':'Voice test',
  '语音测试内容':'Voice test content',
  '请输入语音测试内容':'Please enter the content of the voice test',
  '语音测试成功！': 'Voice test successful!',
  '今日已收货单数': 'the number of orders received today',
  '今日已入库单数': 'the number of units that have been put into storage today',
  '今日已发料单数': 'the odd number of materials that have been issued today',
  '今日已退料单数': 'the singular number of returned materials has been returned today',
  '今日已出库单数': 'the odd number has been shipped out today',
  '烘烤中': 'baking',
  '烘烤状态':'baking state',
  '条码号':'barcode number',
  '烘烤温度':'baking temperature',
  '当前达成率':'current achievement rate',
  '当前完成数':'the current number of completions',
  '工单数':'number of tickets',
  '今日直通率':'today s first pass rate',
  '今日达成率':'today s achievement rate',
  '订单完成进度':'order completion progress',
  '实时产量与达成率':'real time yield and achievement rate',
  '导入license': 'Import license',
  '导出serverInfo': 'Export serverInfo',
  'license文件不存在': 'The license file does not exist',
  '您的系统已过期，当前无法使用系统，请先更新许可证书!': 'Your system has expired and is currently unable to use it. Please update your license certificate first!',
  '服务器 MAC 地址不匹配': 'Server MAC address mismatch',
  '服务器机器码不匹配': 'Server machine code mismatch',
  'license验签失败!': 'License verification failed!',
  '您的系统使用期限还有': 'Your system usage period is about to expire in',
  '天即将过期，过期后将不能继续使用系统，请及时处理!': 'days. Once it expires, you will no longer be able to use the system. Please handle it promptly!',
  '正在出货': 'Shipping in progress',
  '部分出货': 'Partial shipment',
  '已入库': 'Received',
  '锁料完成': 'Lock material completed',
  '正在捡料': 'Picking up materials',
  '单': 'single',
  '组': 'group',
  '部分退料': 'Partial return of materials',
  '已发料': 'Issued materials',
  '包装 ': 'package',
  'A班助拉': 'Class A assistant',
  'B班助拉': 'Class B assistant',
  'A班操作员': 'Class A operator',
  'B班操作员': 'Class B operator',
  '湿敏元器件烘烤剩余时间': 'Remaining baking time for humidity sensitive components',
  '湿敏等级': 'Humidity sensitivity level',
  '生成达成率': 'Generate achievement rate',
}