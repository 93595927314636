export default [

  // 登录
  {
    path: '/login',
    name: 'login',
    meta: { title: '数据看板' },
    component: () => import('@/pages/login/index'),
  },

  // 404
  {
    path: '/:pathMatch(.*)*', 
    name: '404',
    meta: { title: '数据看板' },
    component: () => import('@/pages/login/404'), 
  },
  
]